import React, { useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ChartInfoModal from './ChartInfoModal';
import InfoButton from './InfoButton';
import { ResponsiveLine } from '@nivo/line';
import { timeFormat } from "d3-time-format";
import { timeWeek, timeMonth, timeYear } from 'd3-time';
import cloneDeep from 'lodash/cloneDeep';
import { primaryLight, primaryLightest, secondaryMain, grey } from '../../constants/colors';
import { getMinDataDate, getMinDate, getMaxDataDate, getMaxDate } from './utils';
import { max, min, set } from 'date-fns';

const useStyles = makeStyles((theme) => ({
    content: {
      paddingTop: 12,
    },
    title: {
      paddingBottom: 2,
      color: secondaryMain,
    },
    inlineTitle: {
      display: 'flex',
      alignItems: 'center',
    },
    infoButton: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
    },
    legend: {
      backgroundColor: "#eff2f6",
      border: `1.95px solid ${grey}`,
      borderRadius: "2px",
      padding: "5px",
      display: "inline-flex",
      justifyContent: "center",
      flexWrap: "wrap",
      gap: "15px",
      margin: "auto",
      marginTop: "15px",
    },
    legendItem: {
      cursor: 'pointer',
      alignItems: 'center',
      '& span': {
        fontSize: '0.6rem',
        fontWeight: 'bold',
      },
      '&:hover': {
        backgroundColor: "rgba(0, 0, 0, 0.12)",
        borderRadius: '4px',
      }
    }
  }));

// Converts the Date/Value format to X/Y format for Nivo
function convertLinesXY(lines) {
  let linesCopy = cloneDeep(lines);
  for (let i = 0; i < linesCopy.length; ++i) {
    linesCopy[i].data = linesCopy[i].data.map((item) => ({x: item.date, y: item.value}));
  }
  return linesCopy;
}

// Converts D1 & D2 Date to Line format to plot on the chart
function D1D2_toLine(d1_date, d2_date, delayed_D1, delayed_D2, maxYValue, scale) {
  let dateLines = [];
  if (d1_date) {
    dateLines.push({id: "Vert D1", data: [{x: d1_date, y: 0}, {x: d1_date, y: maxYValue*scale}], strokeStyle: 'vertical', color: "black"});
  }
  if (d2_date) {
    dateLines.push({id: "Vert D2", data: [{x: d2_date, y: 0}, {x: d2_date, y: maxYValue*scale}], strokeStyle: 'vertical', color: "black"});
  }
  if (delayed_D1) {
    dateLines.push({id: "Vert Delayed D1", data: [{x: delayed_D1, y: 0}, {x: delayed_D1, y: maxYValue*scale}], strokeStyle: 'vertical', color: "orange"});
  }
  if (delayed_D2) {
    dateLines.push({id: "Vert Delayed D2", data: [{x: delayed_D2, y: 0}, {x: delayed_D2, y: maxYValue*scale}], strokeStyle: 'vertical', color: "orange"});
  }
  return dateLines;
}

// Finds the max Y value in the data
function getMaxDataPoint(lines) {
  let maxDataPoint = 0;
  for (let i = 0; i < lines.length; ++i) {
    let temp = Math.max(...lines[i].data.map(item => item.y));
    if (temp > maxDataPoint) { maxDataPoint = temp; }
  }
  return maxDataPoint;
}

const dateDifftoWeeks = (date1, date2) => {
  // 604800000 conversion for milli-seconds to weeks
  return (date1 - date2)/604800000;
}

const customTickValues = (tickFormat, xMin, xMax, monthDiff, gridXValues=false) => {
  if (tickFormat) { 
    if (monthDiff > 120) {
      const every8Weeks = timeWeek.every(12).range(xMin, xMax);
      return (dateDifftoWeeks(every8Weeks[0], xMin) > 8 ? [xMin] : []).concat(every8Weeks);
    }
    else if (monthDiff > 60) {
      const every4Weeks = timeWeek.every(4).range(xMin, xMax);
      return (dateDifftoWeeks(every4Weeks[0], xMin) > 4 ? [xMin] : []).concat(every4Weeks);
    }

    return timeWeek.range(xMin, xMax); 
  }

  if (gridXValues) { 
    if (monthDiff < 8) { return timeWeek.range(xMin, xMax); }
  }

  if (monthDiff > 120) {
    const every12Months = timeMonth.every(12).range(xMin, xMax);
    return (dateDifftoWeeks(every12Months[0], xMin) > 24 ? [xMin] : []).concat(every12Months);
  }
  else if (monthDiff > 60) {
    const every6Months = timeMonth.every(6).range(xMin, xMax);
    return (dateDifftoWeeks(every6Months[0], xMin) > 12 ? [xMin] : []).concat(every6Months);
  }
  else if (monthDiff > 15) {
    const every3Months = timeMonth.every(3).range(xMin, xMax);

    if (gridXValues) { return [xMin].concat(every3Months); }
    return (dateDifftoWeeks(every3Months[0], xMin) > 6 ? [xMin] : []).concat(every3Months);
  } else {
    const everyMonth = timeMonth.range(xMin, xMax);
    return (dateDifftoWeeks(everyMonth[0], xMin) > 3 ? [xMin] : []).concat(everyMonth);
  }
  
}

export default function NivoChart(props) {
    // Event Handlers
    const [showCrosshair, setShowCrosshair] = useState(true);
    const [crosshairType, setCrosshairType] = useState('bottom-left');
    const [hoveredPoint, setHoveredPoint] = useState(null);
    const [modalOpen, setModalOpen] = React.useState(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);
    

    const classes = useStyles();
    const { title, lines, scatters, rects, chartHeight, chartTitle, d1_date, d2_date, delayed_D1, delayed_D2, start_date, end_date, tickFormat, xTitle, yAxis2, hideKey, allowHidingLines, allowHoverTooltip } = props;

    const safeLines = lines ? convertLinesXY(lines) : [];
    const safeScatters = scatters ? convertLinesXY(scatters) : [];
    const safeRects = rects ? convertLinesXY(rects) : [];

    const minDataDate = delayed_D1 && (delayed_D1 < getMinDataDate(lines, d1_date)) ? delayed_D1 : getMinDataDate(lines, d1_date);
    const maxDataDate = delayed_D2 && (delayed_D2 > getMaxDataDate(lines, d2_date)) ? delayed_D2 : getMaxDataDate(lines, d2_date);
    const xMin = start_date && (start_date < minDataDate) ? getMaxDate([start_date, minDataDate]) : minDataDate;
    const xMax = end_date ? getMaxDate([end_date, maxDataDate]) : maxDataDate;
    const monthDiff = (xMax.getMonth() - xMin.getMonth()) + (12 * (xMax.getFullYear() - xMin.getFullYear()));
    const maxYValue = !yAxis2 ? getMaxDataPoint(safeLines) : Math.max(yAxis2.rightDomain[1], yAxis2.leftDomain[1]);

    const legendData = ((((safeLines.concat(D1D2_toLine(d1_date, d2_date, delayed_D1, delayed_D2, maxYValue, 1.1))).concat(safeRects)).concat(safeScatters)).filter((line) => line.legend)).sort((a, b) => (a?.keyOrder ?? 1) - (b?.keyOrder ?? 1));

    // Handles hiding/showing lines when clicking on the legend items
    const [hiddenLines, setHiddenLines] = useState([]);
    const safePlottableData = useMemo(
      () => (((safeLines.concat(D1D2_toLine(d1_date, d2_date, delayed_D1, delayed_D2, maxYValue, 1.1))).concat(safeRects)).concat(safeScatters)).filter((item) => !hiddenLines.includes(item.id)),
      [hiddenLines, safeLines]
    );


    // Handles the different line styles (dashed, dotted, solid)
    function strokeType(strokeStyle) {
      if (strokeStyle === "dashed") {
        return { strokeDasharray: "6, 3", strokeWidth: 2 };
      } else if (strokeStyle === "dotted") {
        return { strokeDasharray: "3, 6", strokeWidth: 3 };
      } else if (strokeStyle === "vertical") {
        return { strokeWidth: 1 };
      } else if (strokeStyle === "hidden") {
        return { strokeWidth: 0, opacity: 0, pointerEvents: 'none' };
      }
  
      return { strokeWidth: 2 };
    }
  
    const ChartTitle = ({ width }) => {
      if (!chartTitle) { return null; }

      return (
        <text
          x={width / 2.15}
          y={-18}
          textAnchor="middle"
          dominantBaseline="middle"
          style={{
            fontSize: '13px',
            fontWeight: 'bold',
          }}
        >
          {chartTitle}
        </text>
      );
    };

    // Develops the Dashed, Dotted, and Solid Lines, using strokeStyle to determine which type of line to use
    const DashedSolidLine = ({ series, lineGenerator, xScale, yScale }) => {
      return series
        .filter(({ id }) => !id.includes("Rect"))
        .map(({ id, color, fill, strokeStyle, legend, points, data }) => (
          <>
            <path
              key={id}
              d={lineGenerator(
                data.map((d) => ({
                  x: xScale(d.data.x),
                  y: yScale(d.data.y),
                }))
              )}
              stroke={color}
              style={!points ? strokeType(strokeStyle) : { strokeWidth: 0 }}
              fill="none"
            />
            {points && data.map((d, index) => (
              <circle
                key={`point-${id}-${index}`}
                cx={xScale(d.data.x)}
                cy={yScale(d.data.y)} 
                r={5}
                stroke={color}
                fill={fill}
              />
            ))}
          </>
      ));
    };
  

    // D1 & D2 Date marker to indicate what each vertical line represents (D1 or D2)
    const D1D2Marker = ({ series, xScale, yScale }) => {
      return series
        .filter(({ id }) => id.includes("Vert"))
        .map(({ id, color, strokeStyle, legend, points, data }) => (
          <g transform={`translate(${data[1].position.x},${data[1].position.y})`}>
            <rect
              x={-10}
              y={String(id).includes("Delayed") ? 80 : -7}
              width={20}
              height={15}
              fill="#d4d7de"
              stroke="black"
              strokeWidth={1}
              rx={2}
              ry={2}
            />
            <text
              x={0}
              y={String(id).includes("Delayed") ? 87 : 0}
              fill="black"
              fontSize={10}
              fontWeight="bold"
              textAnchor="middle"
              alignmentBaseline="central"
            >
              {String(id).replace("Vert", "").replace("Delayed", "").trim()}
            </text>
          </g>
        ));
    };


    // Custom Layer to draw Rectangles onto line chart
    const RectLayer = ({ series, xScale, yScale, innerWidth, innerHeight }) => {
      return series
        .filter(({ id }) => id.includes("Rect"))
        .map(({ id, color, opacity, data }) => (
          <rect
            x={data[0].position.x}
            y={data[1].position.y}
            width={Math.abs(data[1].position.x - data[0].position.x)}
            height={Math.abs(data[1].position.y - data[0].position.y)}
            fill={color}
            opacity={opacity}
            style={{ pointerEvents: 'none' }}
          />
        ));
    };


    // Custom Shape for the Legend
    const LegendLineShape = ({ x, y, size, fill, borderWidth, borderColor, id, isCircle }) => {
      const dataItem = safePlottableData.find(item => item.id === id);
      const strokeDasharray = dataItem && (dataItem.strokeStyle === 'dashed' || dataItem.strokeStyle === 'dotted') ? '4, 4' : 'none';
      
      const isPoint = (dataItem && 'points' in dataItem) ? dataItem.points : (isCircle ? true : false);
      
      return isPoint ? 
      (
        <circle
          cx={x + size / 3.1}
          cy={y + size / 3.1}
          r={size / 4}
          fill={dataItem ? dataItem.fill : fill}
          stroke={fill}
          strokeWidth={borderWidth}
          strokeDasharray={strokeDasharray}
          style={{ pointerEvents: 'none' }}
        />
      )
      : (
        <line
          x1={x}
          y1={y + size / 2}
          x2={x + size}
          y2={y + size / 2}
          stroke={fill}
          strokeWidth={4}
          strokeDasharray={strokeDasharray}
          style={{ pointerEvents: 'none' }}
        />
      );
    };

    // Custom Legend to fit legend items properly
    const CustomLegend = ({ legendData }) => {
      return (
        <div className={`${classes.legend}`}>
          {legendData.map(line => (
            <div
              key={line.id}
              className={classes.legendItem}
              onClick={allowHidingLines ? () => setHiddenLines((state) => state.includes(String(line.id)) ? state.filter((item) => item !== line.id) : [...state, String(line.id)]) : undefined}
              style={allowHidingLines ? { cursor: 'pointer' } : { cursor: 'default', backgroundColor: 'rgba(0, 0, 0, 0)' }}
            >
              {line.points ?
              (
                <svg width="20" height="12">
                  <LegendLineShape
                    x={0}
                    y={0}
                    size={20}
                    fill={hiddenLines.includes(line.id) ? "rgba(1, 1, 1, .1)" : line.color}
                    id={line.id}
                    isCircle={true}
                  />
                </svg>
              )
              : (
                <svg width="20" height="3">
                  <LegendLineShape
                    x={0}
                    y={-10}
                    size={20}
                    fill={hiddenLines.includes(line.id) ? "rgba(1, 1, 1, .1)" : line.color}
                    id={line.id}
                  />
                </svg>
              )}
              <br/>
              <span>{line.id}</span>
            </div>
          ))}
        </div>
      );
    };

    // Empty chart if there is no data to display (usually due to an error)
    if (!lines || !lines.length) {
      return (
        <React.Fragment>
          <Card>
            <CardContent className={classes.content}>
              <div className={classes.inlineTitle}>
                <Typography className={classes.title} gutterBottom variant="h3">
                  {title}
                </Typography>
                <div className={classes.infoButton}>
                  <InfoButton onClick={handleModalOpen} />
                </div>
              </div>
              <Divider/>
              <div style={{ height: chartHeight }}/>
            </CardContent>
          </Card>

          <ChartInfoModal open={modalOpen} onClose={handleModalClose}/>
        </React.Fragment>
      );
    }

    const handleMouseMove = point => {
      if (String(point.serieId).includes("Vert") || String(point.serieId).includes("hidden") || !allowHoverTooltip) {
        setShowCrosshair(false);
        return;
      }
      setShowCrosshair(true);
    };

    const standardCrosshairTheme = {
      crosshair: {
        line: {
          stroke: '#000000',
          strokeWidth: 1,
          strokeOpacity: 0.75,
        },
      },
    };

    const hiddenCrosshairTheme = {
      crosshair: {
        line: {
          stroke: 'rgba(0, 0, 0, 0)',
          strokeWidth: 0,
          strokeOpacity: 0,
        },
      },
    };

    return (
      <React.Fragment>
        <Card>
          <CardContent className={classes.content}>
            <div className={classes.inlineTitle}>
              <Typography className={classes.title} gutterBottom variant="h3">
                {title}
              </Typography>
              <div className={classes.infoButton}>
                <InfoButton onClick={handleModalOpen} />
              </div>
            </div>
            <Divider/>
            <div style={ chartTitle ? {height: (parseInt(chartHeight) + 0.2) + 'vw'} : { height: chartHeight }}>
              <ResponsiveLine
                data={safePlottableData}
                xScale={{ type: "time",
                          precision: "day",
                          min: xMin,
                }}
                yScale={{
                  type: "linear",
                  stacked: false,
                  min: 0,
                  max: maxYValue * 1.1,
                }}
                gridXValues={customTickValues(tickFormat, xMin, xMax, monthDiff, true).concat([xMax])}
                layers={[
                  "grid",
                  "markers",
                  "axes",
                  "areas",
                  "crosshair",
                  "line",
                  "slices",
                  "points",
                  "mesh",
                  "legends",
                  RectLayer,
                  DashedSolidLine,
                  D1D2Marker,
                  ChartTitle,
                ]}
                theme={showCrosshair ? standardCrosshairTheme : hiddenCrosshairTheme}
                margin={{ top: (chartTitle ? 30 : 20), right: yAxis2 ? 60 : 10, bottom: 25, left: yAxis2 ? 65 : 45 }}
                axisTop={null}
                axisLeft={{
                  orient: "left",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: yAxis2 ? yAxis2.left : "",
                  legendOffset: -52,
                  format: (value) => yAxis2 && yAxis2.scaleSide == 'left' ? Math.round(`${new Intl.NumberFormat('en-US').format(value*(1/yAxis2.scale))}`) : `${new Intl.NumberFormat('en-US').format(value)}`,
                }}
                axisRight={yAxis2 ? 
                  {
                    orient: "right",
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: yAxis2.right,
                    legendOffset: 52,
                    format: (value) => yAxis2 && yAxis2.scaleSide == 'right' ? Math.round(`${new Intl.NumberFormat('en-US').format(value*(1/yAxis2.scale))}`) : `${new Intl.NumberFormat('en-US').format(value)}`,
                  } : null}
                axisBottom={{
                  orient: "bottom",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  tickValues: customTickValues(tickFormat, xMin, xMax, monthDiff),
                  format: tickFormat ? tickFormat : (value) => timeFormat("%b")(value) === "Jan" ? String(timeFormat("%m/%d/%Y")(value)).split("/")[2] : timeFormat("%b")(value),
                  legend: xTitle ? xTitle : "",
                  legendOffset: -10,
                }}
                useMesh={true}
                enableCrosshair={true}
                crosshairType={crosshairType}
                enablePoints={false}
                onMouseMove={handleMouseMove}
                tooltip={({ point }) => {
                  if (String(point.serieId).includes("Vert") || String(point.serieId).includes("hidden") || !allowHoverTooltip) {
                    return (
                      <div style={{ background: "rgba(255, 255, 255, 0.5)" }}></div>
                    );
                  }

                  let value = null;
                  if (yAxis2 && point.serieId === (yAxis2.scaleSide == 'left' ? yAxis2.left : yAxis2.right)) {
                    value = `${new Intl.NumberFormat('en-US').format(point.data.y*(1/yAxis2.scale))}`;
                  }
                  
                  if (yAxis2 && point.serieId === yAxis2.right) {
                    setCrosshairType('bottom-right');
                  } else { setCrosshairType('bottom-left'); }

                  return (
                    <div
                      style={{
                        background: "white",
                        padding: "10px",
                        border: "1px solid #ccc",
                      }}
                    >
                      Date:{" "}
                      {point.data.x.toLocaleDateString("en-US", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                      })}
                      <br />
                      {point.serieId}: {value ? value : point.data.y}
                    </div>
                  );
                }}
                colors={{ datum: "color" }}
              />
            </div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <CustomLegend legendData={legendData} />
            </div>
          </CardContent>
        </Card>

        <ChartInfoModal open={modalOpen} onClose={handleModalClose}/>
      </React.Fragment>
    );
}
