import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssessmentIcon from '@material-ui/icons/Assessment';
import LaunchIcon from '@material-ui/icons/Launch';
import FolderIcon from '@material-ui/icons/Folder';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import GroupIcon from '@material-ui/icons/Group';
import Switch from '@material-ui/core/Switch';
import {headerHeight, sidebarClosedWidth, sidebarOpenWidth} from '../../../constants/dimensions';
import { darkGrey, primaryLight, secondaryMain } from '../../../constants/colors';

import { unifiedRoutes, mpmRoutes, ppmRoutes, ipmRoutes, prmRoutes, lpmRoutes } from '../../../views/dashboard/routes';
import { configRoutes } from '../../../views/user_inputs/routes';
import { trackerRoutes } from '../../../views/tracker/routes';

import NestedMenu from './NestedMenu';
import NestedMenuItem from './NestedMenuItem';
import { useLocation } from 'react-router-dom';
import { companyRoutes } from '../../../views/company/routes';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: sidebarOpenWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'column',
  },
  drawerOpen: {
    width: sidebarOpenWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    width: sidebarClosedWidth,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerPaper: {
    paddingTop: headerHeight,
    background: primaryLight,
    borderRight: `1px solid ${darkGrey}`,
  },
  menuItem: {
    background: 'white',
  },
  versionNumber: {
    marginTop: 4,
    fontSize: 12,
    fontWeight: 'bold',
  },
  demoIndicator: {
    marginTop: 6,
    fontSize: 12,
    fontWeight: 'bold',
    color: secondaryMain,
  },
  demoToggle: {
    flexGrow: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  toggleLabel: {
    fontSize: 12,
  },
  icon: {
    width: 36,
    color: darkGrey,
  },
  launchIcon: {
    width: 12,
    height: 12,
  },
  image: {
    width: 24,
    margin: '0 auto',
    paddingTop: 6,
  },
  showFooter: {
    paddingTop: 8,
    display: 'block',
  },
  hideFooter: {
    paddingTop: 8,
    display: 'none',
  },
  sidebarFooterItem: {
    padding: 4,
    width: '100%',
    textAlign: 'center',
    display: 'block',
    fontSize: 12,
    fontWeight: 'bold',
    color: 'black',
    textDecoration: 'none',
    "&:hover": {
      textDecoration: 'underline',
    },
  },
  coreUiLinkLayout: {
    display: 'flex', 
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

function Sidebar(props) {
  const classes = useStyles();

  const { isAdmin, demoEnabled, is_owner } = props;

  const [open, setOpen] = React.useState(false);
  const [unifiedExpanded, setUnifiedExpanded] = React.useState(true);
  const [mpmExpanded, setMpmExpanded] = React.useState(false);
  const [ppmExpanded, setPpmExpanded] = React.useState(false);
  const [ipmExpanded, setIpmExpanded] = React.useState(false);
  const [prmExpanded, setPrmExpanded] = React.useState(false);
  const [lpmExpanded, setLpmExpanded] = React.useState(false);
  const [inputsExpanded, setInputsExpanded] = React.useState(false);
  const [trackerExpanded, setTrackerExpanded] = React.useState(false);
  const [companyExpanded, setCompanyExpanded] = React.useState(false);
  const location = useLocation();

  const expand = (excluded) => {
    setUnifiedExpanded(excluded === 'unified' && !unifiedExpanded);
    setMpmExpanded(excluded === 'mpm' && !mpmExpanded);
    setPpmExpanded(excluded === 'ppm' && !ppmExpanded);
    setIpmExpanded(excluded === 'ipm' && !ipmExpanded);
    setPrmExpanded(excluded === 'prm' && !prmExpanded);
    setLpmExpanded(excluded === 'lpm' && !lpmExpanded);
    setInputsExpanded(excluded === 'inputs' && !inputsExpanded);
    setTrackerExpanded(excluded === 'tracker' && !trackerExpanded);
    setCompanyExpanded(excluded === 'company' && !companyExpanded);
  }

  const renderMenuItems = (items) => (
    items.map((item, i) => {
      if (item.adminOnly && !isAdmin) return;
      const path = demoEnabled ? `/demo${item.path}` : item.path;

      return (
        <NestedMenuItem key={i} label={item.name} path={path} />
      )
    })
  );

  const drawerProps = {
    variant: 'permanent',
    onMouseOver: () => setOpen(true),
    onMouseOut: () => setOpen(false),
    className: clsx(classes.drawer, {[classes.drawerOpen]: open, [classes.drawerClose]: !open}),
    classes: {
      paper: clsx(classes.drawerPaper, {[classes.drawerOpen]: open, [classes.drawerClose]: !open}),
    },
    PaperProps: {
      elevation: 2,
    },   
  }

  const unifiedMenuProps = {
    expanded: open && unifiedExpanded,
    onClick: () => expand('unified'),
    items: renderMenuItems(unifiedRoutes),
    label: 'TBC',
    icon: <AssessmentIcon className={classes.icon} />,
  };

  const mpmMenuProps = {
    expanded: open && mpmExpanded,
    onClick: () => expand('mpm'),
    items: renderMenuItems(mpmRoutes),
    label: 'Defect Trend',
    icon: <AssessmentIcon className={classes.icon} />,
  };

  const ppmMenuProps = {
    expanded: open && ppmExpanded,
    onClick: () => expand('ppm'),
    items: renderMenuItems(ppmRoutes),
    label: 'Early Prediction (Planning Phase)',
    tooltipText: 'Early prediction without actual defects',
    icon: <AssessmentIcon className={classes.icon} />,
  };

  const ipmMenuProps = {
    expanded: open && ipmExpanded,
    onClick: () => expand('ipm'),
    items: renderMenuItems(ipmRoutes),
    label: 'Early Prediction (Testing Phase)',
    tooltipText: 'Early prediction with actual defects',
    icon: <AssessmentIcon className={classes.icon} />,
  };

  const prmMenuProps = {
    expanded: open && prmExpanded,
    onClick: () => expand('prm'),
    items: renderMenuItems(prmRoutes),
    label: 'Previous Release Data',
    icon: <AssessmentIcon className={classes.icon} />,
  };

  const lpmMenuProps = {
    expanded: open && lpmExpanded,
    onClick: () => expand('lpm'),
    items: renderMenuItems(lpmRoutes),
    label: 'Customer Defects',
    icon: <AssessmentIcon className={classes.icon} />,
  };

  const inputsMenuProps = {
    expanded: open && inputsExpanded,
    onClick: () => expand('inputs'),
    items: renderMenuItems(configRoutes),
    label: 'User Inputs',
    icon: <AssignmentIcon className={classes.icon} />,
  };

  const trackerMenuProps = {
    expanded: open && trackerExpanded,
    onClick: () => expand('tracker'),
    items: renderMenuItems(trackerRoutes),
    label: 'User Events',
    icon: <AssignmentIcon className={classes.icon} />,
  };

  const companyMenuProps = {
    expanded: open && companyExpanded,
    onClick: () => expand('company'),
    items: renderMenuItems(companyRoutes),
    label: 'Company',
    icon: <AssignmentIcon className={classes.icon} />,
  };

  const isLocal = window.location.hostname === 'localhost';
  const isStaging = window.location.hostname.startsWith('staging-dashboard');

  const handleToggleDemo = (event) => {
    if (event.target.checked) {
      window.location.href = `${window.location.origin}/demo${location.pathname}`;
    }
    else {
      window.location.href = `${window.location.origin}${location.pathname.substring(5)}`;
    }
  };

  const coreUiTag = (url, displayName) => {
    return (
      <a href={url} className={classes.sidebarFooterItem} target="_blank" rel="noopener noreferrer">
        <div className={classes.coreUiLinkLayout} >
          { displayName } 
          &nbsp; <LaunchIcon className={classes.launchIcon} />
        </div>
      </a>
    );
  };

  return (
      <Drawer {...drawerProps}>
        <List className={classes.menuItem}>
          { false && <NestedMenu {...unifiedMenuProps} /> }
          <NestedMenu {...mpmMenuProps} />
          <NestedMenu {...ppmMenuProps} />
          <NestedMenu {...ipmMenuProps} />
          { (isLocal || isStaging) && <NestedMenu {...prmMenuProps} /> }
          { (isLocal || isStaging) && <NestedMenu {...lpmMenuProps} /> }
          <NestedMenu {...inputsMenuProps} />
          {isAdmin && <NestedMenu {...trackerMenuProps} />}
          {is_owner && <NestedMenu {...companyMenuProps} />}
        </List>
        <Divider />
        <Typography align='center' className={classes.versionNumber}>v2.1</Typography>
        <img alt='logo' className={classes.image} src={process.env.PUBLIC_URL + '/icon.png'} />
        { demoEnabled && <Typography align='center' className={classes.demoIndicator}>DEMO</Typography> }

        <div className={clsx({[classes.showFooter]: open, [classes.hideFooter]: !open})}>
          { coreUiTag(`${process.env.REACT_APP_LANDING_URL}/video-library/overview`, 'STAR Overview') }
          { coreUiTag(`${process.env.REACT_APP_LANDING_URL}/user-guide`, 'User Guide') }
          { coreUiTag(`${process.env.REACT_APP_LANDING_URL}/demo-data`, 'Demo Data') }
          { coreUiTag(`${process.env.REACT_APP_PRIVACY_POLICY_URL}`, 'Privacy Policy') }
          { coreUiTag(`${process.env.REACT_APP_CONTACT_US_URL}`, 'Contact Us') }
          { coreUiTag(`${process.env.REACT_APP_FREE_TRIAL_URL}`, 'Free Trial') }
        </div>

        { isLocal && (
          <div className={classes.demoToggle}>
            <Typography className={classes.toggleLabel}>Toggle</Typography>
            <Typography className={classes.toggleLabel}>Demo</Typography>
            
            <Switch
              checked={demoEnabled}
              onChange={handleToggleDemo}
              color="primary"
              name="toggleDemo"
            />
          </div>
        )}
      </Drawer>
      )
}

function mapStateToProps(state) {
  return {
    isAdmin: state.common.isAdmin,
    demoEnabled: state.common.demoEnabled,
    is_owner: state.common.is_owner,
  };
} 

export default connect(mapStateToProps)(Sidebar)