export const SET_COMPANY = "SET_COMPANY";
export const SET_PROJECT = "SET_PROJECT";
export const SET_RELEASE = "SET_RELEASE";
export const SET_RELEASE_NULL = "SET_RELEASE_NULL";
export const SET_COMPONENT_FILTER = "SET_SELECTED_COMPONENTS";
export const SET_SEVERITY_FILTER = "SET_SELECTED_SEVERITIES";
export const RESET_DEFECT_FILTERS = "RESET_DEFECT_FILTERS";
export const SHOW_DEFECT_FILTERS = "SHOW_DEFECT_FILTERS";
export const HIDE_DEFECT_FILTERS = "HIDE_DEFECT_FILTERS";
export const SHOW_RELEASE_SELECTOR = "SHOW_RELEASE_SELECTOR";
export const HIDE_RELEASE_SELECTOR = "HIDE_RELEASE_SELECTOR";
export const SET_DEMO_STATE = "SET_DEMO_STATE";
export const SET_COMPANY_LIST = "SET_COMPANY_LIST";
export const SET_PROJECT_LIST = "SET_PROJECT_LIST";
export const SET_RELEASE_LIST = "SET_RELEASE_LIST";
export const SET_USER_AUTHENTICATED = 'SET_USER_AUTHENTICATED';
export const SET_CRITICAL_ERROR = "SET_CRITICAL_ERROR";
export const SET_COMPANY_EXPIRED = 'SET_COMPANY_EXPIRED';
export const SET_NO_COMPANIES = 'SET_NO_COMPANIES';
export const SET_IS_OWNER = 'SET_IS_OWNER';
