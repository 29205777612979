import store from '../../store';
import {
  SET_COMPANY,
  SET_PROJECT,
  SET_RELEASE,
  SET_RELEASE_NULL,
  SET_COMPONENT_FILTER,
  SET_SEVERITY_FILTER,
  RESET_DEFECT_FILTERS,
  SHOW_DEFECT_FILTERS,
  HIDE_DEFECT_FILTERS,
  SHOW_RELEASE_SELECTOR,
  HIDE_RELEASE_SELECTOR,
  SET_COMPANY_LIST,
  SET_PROJECT_LIST,
  SET_RELEASE_LIST,
  SET_CRITICAL_ERROR,
  SET_DEMO_STATE,
  SET_COMPANY_EXPIRED,
  SET_NO_COMPANIES,
  SET_IS_OWNER,
} from "./actionTypes";
import { parseDate } from '../data_display/utils';

const axios = require('axios').default;

const companyListUrl = () => `/companies`;
const projectListUrl = (company) => `/companies/${company.id}/projects`;
const isOwnerUrl = (company) => `/companies/${company.id}/is-owner`;
const releaseListUrl = (project) => `/projects/${project.id}/releases`;
const releaseUrl = (release) => `/releases/${release.id}`;


export function initializeApp() {
  return async dispatch => {
    const { jwtToken, baseApiUri } = store.getState().common;

    const url = `${baseApiUri}${companyListUrl()}`;
    const headers = { Authorization: jwtToken };

    return axios.get(url, {headers}).then(
      response => {
        dispatch({ type: SET_COMPANY_LIST, ...response.data });

        const { companies } = response.data;

        let company = companies[0];
        const companyId = localStorage.getItem('companyId');
        if (companyId) {
          const matchingCompany = companies.find(x => x.id === +companyId);
          if (matchingCompany) {
            company = matchingCompany;
          }
        }

        dispatch(setCompany(company));
      },
      error => {
        dispatch({ type: SET_CRITICAL_ERROR, error: 'There was an error.' });
        throw error;
      }
    )
  }
}


export function setCompany(company) {
  return async dispatch => {
    let isExpired = false;

    dispatch({ type: SET_COMPANY, company });
    if (company === undefined) {
      // Automatically route the user to the account page
      dispatch({ type: SET_NO_COMPANIES });
    }
    else if (company.membership_expires)
    {
      const endDate = parseDate(company.membership_expires);
      const now = new Date();
      now.setHours(0, 0, 0);
      if (endDate < now) {
        isExpired = true;
        dispatch({ type: SET_COMPANY_EXPIRED, isExpired });
      }
    }

    if (company) {
      dispatch({ type: SET_IS_OWNER, is_owner: false });
      dispatch(getIsOwner());
    }

    if (company && !isExpired) {
      dispatch({ type: SET_COMPANY_EXPIRED, isExpired: isExpired });
      localStorage.setItem('companyId', company.id);
      dispatch(getProjectList());
    }
  }
}

export function getIsOwner() {
  return async dispatch => {
    const { company, jwtToken, baseApiUri } = store.getState().common;

    const url = `${baseApiUri}${isOwnerUrl(company)}`;
    const headers = { Authorization: jwtToken };

    return axios.get(url, {headers}).then(
      response => {
        dispatch({ type: SET_IS_OWNER, ...response.data });
      }
    )
  }
}

export function getProjectList() {
  return async dispatch => {
    const { company, jwtToken, baseApiUri, project } = store.getState().common;

    const url = `${baseApiUri}${projectListUrl(company)}`;
    const headers = { Authorization: jwtToken };

    return axios.get(url, {headers}).then(
      response => {
        dispatch({ type: SET_PROJECT_LIST, ...response.data });

        const { projects } = response.data;

        let selectedProject = projects[0];
        if (project) {
          const existingProject = projects.find(x => x.id === project.id);
          if (existingProject) {
            selectedProject = existingProject;
          }
        }

        dispatch(setProject(selectedProject));
      },
      error => {
        dispatch({ type: SET_CRITICAL_ERROR, error: 'There was an error.' });
        throw error
      }
    )
  }
}


export function setProject(project) {
  return async dispatch => {
    dispatch({ type: SET_PROJECT, project });
    dispatch(getReleaseList(project));
  }
}


export function getReleaseList() {
  return async dispatch => {
    const { project, jwtToken, baseApiUri, release } = store.getState().common;

    const url = `${baseApiUri}${releaseListUrl(project)}`;
    const headers = { Authorization: jwtToken };

    return axios.get(url, {headers}).then(
      response => {
        dispatch({ type: SET_RELEASE_LIST, ...response.data });

        const { releases } = response.data;

        let selectedRelease = releases[0];
        if (release) {
          const existingRelease = releases.find(x => x.id === release.id);
          if (existingRelease) {
            selectedRelease = existingRelease;
          }
        }

        if (selectedRelease) {
          dispatch(setRelease(selectedRelease));
        }
        else {
          dispatch({ type: SET_RELEASE_NULL });
        }
      },
      error => {
        dispatch({ type: SET_CRITICAL_ERROR, error: 'There was an error.' });
        throw error
      }
    )
  }
}


export function setRelease(release) {
  return async dispatch => {
    const { jwtToken, baseApiUri } = store.getState().common;

    const url = `${baseApiUri}${releaseUrl(release)}`;
    const headers = { Authorization: jwtToken };

    return axios.get(url, {headers}).then(
      response => {
        dispatch({ type: RESET_DEFECT_FILTERS });
        dispatch({ type: SET_RELEASE, release: release, response: response.data });
      },
      error => {
        dispatch({ type: SET_CRITICAL_ERROR, error: 'There was an error.' });
        throw error
      }
    )
  }
}

export function setComponentFilter(componentFilter) {
  return async dispatch => {
    dispatch({ type: SET_COMPONENT_FILTER, componentFilter });
  }
}


export function setSeverityFilter(severityFilter) {
  return async dispatch => {
    dispatch({ type: SET_SEVERITY_FILTER, severityFilter });
  }
}


export function showDefectFilters() {
  return async dispatch => {
    dispatch({ type: SHOW_DEFECT_FILTERS })
  }
}


export function hideDefectFilters() {
  return async dispatch => {
    dispatch({ type: HIDE_DEFECT_FILTERS })
    dispatch({ type: SET_COMPONENT_FILTER, componentFilter: [] });
    dispatch({ type: SET_SEVERITY_FILTER, severityFilter: [] });
  }
}


export function showReleaseSelector() {
  return async dispatch => {
    dispatch({ type: SHOW_RELEASE_SELECTOR })
  }
}


export function hideReleaseSelector() {
  return async dispatch => {
    dispatch({ type: HIDE_RELEASE_SELECTOR });
  }
}


export function activateDemo() {
  return async dispatch => {
    dispatch({ type: SET_DEMO_STATE, enabled: true, baseApiUri: process.env.REACT_APP_DEMO_BASE_URL });
  }
}

export function deactivateDemo() {
  return async dispatch => {
    dispatch({ type: SET_DEMO_STATE, enabled: false, baseApiUri: process.env.REACT_APP_BASE_URL });
  }
}

export function getReport(){};
