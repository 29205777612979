import React from 'react';
import { connect } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import ViewTitle from '../../common/layout/ViewTitle';
import AddUserModal from './AddUserModal';

const axios = require('axios').default;

const columns = [
  {
    field: 'email',
    headerName: 'Email',
    width: 340,
    editable: false,
  },
  {
    field: 'role',
    headerName: 'Role',
    width: 150,
    editable: false,
  },
  {
    field: 'invited',
    headerName: 'Pending Invite',
    width: 150,
    editable: false,
    valueGetter: (row) => {
      if (row.value) {
        return 'Yes';
      }
      return '';
    },
  },
];

function UserManagementView(props) {
  const { jwtToken, baseApiUri, company } = props;

  const [users, setUsers] = React.useState([]);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    const headers = { Authorization: jwtToken };

    if (jwtToken && baseApiUri && company) {
      axios.get(`${baseApiUri}/companies/${company.id}/users`, { headers })
      .then(response => {
        setUsers(response.data.users);
      })
      .catch(err => {
        setError(true);
      })
    }
  }, [baseApiUri, jwtToken, company]);

  return (
    <React.Fragment>
      <ViewTitle title='User management'>
        <AddUserModal onClick={() => {}} />
      </ViewTitle>

      <Card sx={{ width: "100%", "padding": "24px" }}>
        {users && (
          <Box sx={{ "height": "640px" }}>
            <DataGrid
              rows={users.map((event, idx) => ({ ...event, id: idx }))}
              columns={columns}
              autoPageSize
            />
          </Box>
        )}
      </Card>
    </React.Fragment>
  )
}

function mapStateToProps(state) {
  return {
    jwtToken: state.common.jwtToken,
    baseApiUri: state.common.baseApiUri,
    company: state.common.company,
  };
} 

export default connect(mapStateToProps)(UserManagementView)
