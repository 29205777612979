import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { SubmitButton } from '../../common/forms/buttons';
import { secondaryMain } from '../../constants/colors';

const axios = require('axios').default;

const useStyles = makeStyles((theme) => ({
  addInviteButton: {
    fontSize: 10,
    padding: '3px 12px ',
    marginTop: -6,
    fontWeight: 'bold',
    width: 180,
    float: 'right',
  },
  icon: {
    fontSize: 18,
    paddingLeft: 4,
  },
  modalBody: {
    position: 'absolute',
    top: 'calc(50% - 100px)',
    left: 'calc(50% - 200px)',
    width: 400,
    height: 200,
    outline: 'none',
    border: 'none',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
  },
  form: {
    height: '100%',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
  },
  input: {
    width: '100%',
  },
  submitButton: {
    width: '100%',
  },
  modalTitle: {
    paddingBottom: 2,
    color: secondaryMain,
  },
  error: {
    color: 'red',
    fontWeight: 'bold',
  },
}));

function AddModal(props) {
  const classes = useStyles();
  const { baseApiUri, jwtToken, afterSave, company } = props;

  const [modalOpen, setModalOpen] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState(undefined);
  const [email, setEmail] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState(undefined);

  const openModal = (e) => {
    setModalOpen(true);
    e.stopPropagation();
  };

  const handleClose = (e) => {
    setModalOpen(false);
    setEmail('');
    e.stopPropagation();
    e.preventDefault();
  };  
  
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const onKeyDown = (e) => {
    if (e.key !== "Escape") {
      e.stopPropagation();
    }
  };

  const onChange = (e) => {
    setEmail(e.target.value);
  };

  const onSave = () => {
    const headers = { Authorization: jwtToken };
    setErrorMessage(undefined);

    const body = {
      email,
    };

    axios.post(`${baseApiUri}/companies/${company.id}/users`, body, {headers})
      .then(response => {
        setSnackbarMessage(response.data.message);
        setSnackbarOpen(true);
        setModalOpen(false);
        afterSave && afterSave();
      })
      .catch(error => {
        console.log(error);
        setErrorMessage(`There was an error adding user ${email}.`);
      })
  };

  const addInviteButtonProps = {
    color: "secondary",
    variant: "contained",
    className: classes.addInviteButton,
    onClick: openModal,
  };

  return (
    <>
    <Button {...addInviteButtonProps}>
      Add/Invite User <PersonAddIcon className={classes.icon} />
    </Button>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />

      <Modal
        open={modalOpen}
        onClose={handleClose}
      > 
        <div className={classes.modalBody} onClick={(e) => e.stopPropagation()}>
          <form className={classes.form} onSubmit={ (e) => { onSave(); e.preventDefault(); } }>
            <Typography className={classes.modalTitle} gutterBottom variant='h3'>
              Add User
            </Typography>
            <Divider />

            <TextField
              autoFocus
              className={classes.input}
              label={`Email`}
              value={email}
              variant='outlined'
              onChange={onChange}
              onKeyDown={onKeyDown}
              required
            />

            <SubmitButton className={classes.submitButton} text='Save' />

            <Typography className={classes.error}>{errorMessage}</Typography>
          </form>
        </div>
      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  return {
    jwtToken: state.common.jwtToken,
    baseApiUri: state.common.baseApiUri,
    company: state.common.company,
  };
} 

export default connect(mapStateToProps)(AddModal);