import {
  SET_COMPANY,
  SET_PROJECT,
  SET_RELEASE,
  SET_RELEASE_NULL,
  SET_COMPONENT_FILTER,
  SET_SEVERITY_FILTER,
  SET_COMPANY_LIST,
  SET_PROJECT_LIST,
  SET_RELEASE_LIST,
  SHOW_DEFECT_FILTERS,
  HIDE_DEFECT_FILTERS,
  SHOW_RELEASE_SELECTOR,
  HIDE_RELEASE_SELECTOR,
  RESET_DEFECT_FILTERS,
  SET_USER_AUTHENTICATED,
  SET_CRITICAL_ERROR,
  SET_DEMO_STATE,
  SET_COMPANY_EXPIRED,
  SET_NO_COMPANIES,
  SET_IS_OWNER,
} from "./actionTypes";

const initialState = {
  company: undefined,
  project: undefined,
  release: undefined,
  componentFilter: [],
  severityFilter: [],
  companyList: [],
  projectList: [],
  releaseList: [],
  componentList: [],
  severityList: [],
  showDefectFilter: false,
  showReleaseSelector: false,
  releaseNull: false,
  isAuthenticated: false,
  isAdmin: false,
  jwtToken: undefined,
  criticalError: undefined,
  demoEnabled: undefined,
  baseApiUri: '',
  company_expired: false,
  no_companies: false,
  is_owner: false,
};

export default function Reducer(state=initialState, action) {
  switch (action.type) {

    case SET_COMPANY: {
      const { company } = action;

      const no_companies = company ? false : state.no_companies;

      return {
        ...state,
        company,
        no_companies,
        project: undefined,
        release: undefined,
        componentFilter: [],
        severityFilter: [],
        projectList: [],
        releaseList: [],
        componentList: [],
        severityList: [],
      }
    }

    case SET_PROJECT: {
      const { project } = action;
      return { ...state, project, releaseNull: false, release: undefined }
    }

    case SET_RELEASE: {
      const { release, response } = action;

      const defectFilterConfig = response.defect_filter_config;
      const componentList = defectFilterConfig ? defectFilterConfig.components.sort((a, b) => (a.name > b.name) ? 1 : -1) : [];
      const severityList = defectFilterConfig ? defectFilterConfig.severities.sort((a, b) => (a.name > b.name) ? 1 : -1) : [];

      return { ...state, release, componentList, severityList }
    }

    case SET_RELEASE_NULL: {
      return { ...state, releaseNull: true }
    }

    case SET_COMPONENT_FILTER: {
      const { componentFilter } = action;
      return { ...state, componentFilter }
    }

    case SET_SEVERITY_FILTER: {
      const { severityFilter } = action;
      return { ...state, severityFilter }
    }

    case RESET_DEFECT_FILTERS: {
      return { ...state, componentFilter: [], severityFilter: [] }
    }

    case SHOW_DEFECT_FILTERS: {
      return { ...state, showDefectFilter: true }
    }

    case HIDE_DEFECT_FILTERS: {
      return { ...state, showDefectFilter: false }
    }

    case SHOW_RELEASE_SELECTOR: {
      return { ...state, showReleaseSelector: true }
    }

    case HIDE_RELEASE_SELECTOR: {
      return { ...state, showReleaseSelector: false }
    }

    case SET_COMPANY_LIST: {
      const { companies } = action;
      return { ...state, companyList: companies }
    }

    case SET_PROJECT_LIST: {
      const { projects } = action;
      return { ...state, projectList: projects }
    }

    case SET_RELEASE_LIST: {
      const { releases } = action;
      return { ...state, releaseList: releases }
    }

    case SET_USER_AUTHENTICATED: {
      const { isAuthenticated, isAdmin, jwtToken } = action;
      return { ...state, isAuthenticated, isAdmin, jwtToken } 
    }

    case SET_DEMO_STATE: {
      const { enabled, baseApiUri } = action;
      return { ...state, demoEnabled: enabled, baseApiUri }; 
    }

    case SET_CRITICAL_ERROR: {
      const { error } = action;
      return { ...state, criticalError: error } 
    }

    case SET_COMPANY_EXPIRED: {
      const { isExpired } = action;

      return { ...state, company_expired: isExpired, no_companies: false } 
    }

    case SET_NO_COMPANIES: {
      return { ...state, no_companies: true, company_expired: false } 
    }

    case SET_IS_OWNER: {
      const { is_owner } = action;
      return { ...state, is_owner } 
    }
    
    default: {
      return state;
    }
  }
}